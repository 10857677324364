import React from 'react'
import Slider from './slider'

const Team = () => {
  return (
    <section className="section-personnel" id="team">
        <div className='container'>
          <h2 className="section-title team-title">THE TEAM BUILDING SILTA</h2>
        </div>
        <Slider />
    </section>
  )
}

export default Team