
import React from 'react'

const WorkWithUs = () => {
  return (
    <section className='section-work'>
      <div className="container">
            <span className="work-section-title">WORK WITH US</span>
            <button className="email-link">
            <a href="https://siltafinance.teamtailor.com/" target='_blank' rel="noreferrer">
            SEE OPEN POSITIONS 
            <div className='external-link-icon' />
            </a>
            </button>
      </div>
    </section>
  )
}

export default WorkWithUs