import React, { Component } from "react";
import Slider from "react-slick";

export default class SliderPersonnel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state =  {
      slideIndex: 0,
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
  
    var settings = {
      className: "sangre-slider personnel-slider",
      dots: false,
      centerMode: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      variableWidth: true,
      arrows: false,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 1200,
          settings: {

          }
        },
        {
          breakpoint: 1024,
          settings: {

          }
        },
        {
          breakpoint: 700,
          settings: {
            variableWidth: false,
          }
        },
      ]
    };

    return (
      <>
        <div className="personnel-slider-container">
          <div className="slider-title">
            <div className='container'>
              <div className={`${this.props.title === 'STRATEGIC ADVISORS' && 'container-advisors'} slider-title-container`}>
                  <div className={(this.props.title === 'STRATEGIC ADVISORS' && 'container-advisors-div') || undefined}>
                    <h2>{this.props.title}</h2>
                  </div>
                  <div className="custom-slick-arrows">
                    <button className="custom-slick-arrow custom-slick-prev" disabled={this.slideIndex > 0} onClick={this.previous}>
                      Previous
                    </button>
                    <button className="custom-slick-arrow custom-slick-next" onClick={this.next}>
                      Next
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {this.props.children}
          </Slider>
        </div>
      </>
    );
  }
}