import React from 'react'

const Numbers = () => {
  return (
    <section className="section-numbers">
      <div className="container">
        <div className="section-numbers-content">
            <div className='d-xs-none d-sm-block desktop-container'>
            <div className='row'>
                    <div className='col-md-11 col-lg-10 col-xl-10 col-xxl-7'>
                        <p className='section-title-main'>
                        The Silta mission is to direct finance towards sustainable infrastructure.
                        </p>
                    </div>
                </div>
                <div className='container pb-5'>
                <div className='row row1 mt-5' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                    <div className='col-md-4 col-xl-4 col-xxl-3' />
                    <div className='col-md-8'>
                        <div className='title-container w-100'>
                        <p className='main-title'>$3.3 trillion</p>
                        </div>
                        <p className='main-text'>Size of the global <br/> infrastructure market*</p>
                    </div>
                </div>

                <div className='row my-5 d-xs-none d-md-flex' />

                <div className='row row2' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                    <div className='col-md-10 col-lg-8 col-xl-6 col-xxl-4 ml-5 d-flex flex-column'>
                        <div className='d-flex align-items-end'>
                            <div className='d-flex align-items-start flex-column'>
                                <div className='title-container mb-0'>
                                    <p className='main-title mb-0'>$350</p>
                                </div>
                                <div className='title-container double-line-mg'>
                                <p className='main-title mt-0'>billion</p>
                                </div>
                            </div>
                        <div className='ml-3 mt-2 mb-2 mb-xl-0 pr-xl-4'>
                            <p className='main-text'>Annual shortfall in <br/>investment*</p>
                        </div>
                        </div>
                        <div className='source-text mt-xl-3 mt-xxl-3'>
                            *Source: <a href="https://infrastructure.aecom.com/infrastructure-funding" target="_blank" rel="noreferrer">AECOM: The Infrastructure Gap:<br/>Financing and Funding the Future</a>
                        </div>
                </div>
                    <div className='col-sm-6' />
                </div>
                    <div className='row my-5' />

                    <div className='row row3' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                        <div className='col-md-4 col-xl-5 col-xxl-6'/>
                        <div className='col-md-8 col-xl-7 col-xxl-5'>
                        <div className='title-container'>
                            <p className='main-title fit-title'>$275 billion</p>
                        </div>
                            <p className='main-text ml-md-1 mb-3'>In sustainable infrasctructure<br/>projects announced in 2020**</p>
                        </div>
                    </div>

                    <div className='row my-5' />

                    <div className='row row4' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                        <div className='col-xl-4 col-xxl-3 col-md-0'/>
                        <div className='col-md-6 col-lg-4 col-xl-5 col-xxl-3'>
                        <div className='title-container'>
                            <p className='main-title'>35%</p>
                        </div>
                            <p className='main-text pr-xl-4'>of all infrastructure projects globally are sustainable, up from 10% a decade ago**</p>
                        </div>
                        <div className='col-md-6 pl-md-3 col-lg-4 col-xl-3 col-xxl-3 d-flex align-items-end'>
                            <div className='source-text padding-none'>
                                **Source: <a href="https://www.refinitiv.com/en/infrastructure-investing/insights/sustainable-infrastructure" target="_blank" rel="noreferrer">Refinitiv: The Sustainable Infrastructure Investment Report</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                {/* MOBILE VIEW */}
                <div className='d-flex flex-column align-items-start d-xs-flex d-md-none'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className='section-title-main mb-5'>
                            The Silta mission is to direct finance towards sustainable infrastructure.
                            </p>
                        </div>
                    </div>

                    <div className='px-5 container'>
                        <div className='row row5 ml-0'>
                            <div className='col-xs-11 xs-number-column'>
                                <div className='title-container w-100'>
                                <p className='main-title'>$3.3 trillion</p>
                                </div>
                                <p className='main-text'>Size of the global <br/> infrastructure market*</p>
                            </div>
                        </div>
                        <div className='row w-100 mt-4 ml-0 row6'>
                            <div className='col-xs-11 xs-number-column w-100'>
                                <div className='title-container'>
                                <p className='main-title'>$350 billion</p>
                                </div>
                                <p className='main-text'>Annual shortfall in <br/>investment*</p>
                                <div className='source-text mt-1'>
                                    *Source: <a href="https://infrastructure.aecom.com/infrastructure-funding" target='_blank' rel="noreferrer">AECOM: The Infrastructure Gap: Financing and Funding the Future</a>
                                </div>
                            </div>
                        </div>

                        <div className='row w-100 mt-4 ml-0 row7'>
                            <div className='col-xs-11 xs-number-column w-100'>
                                <div className='title-container'>
                                <p className='main-title'>$275 billion</p>
                                </div>
                                <p className='main-text'>In sustainable infrasctructure<br/>projects announced in 2020**</p>
                            </div>
                        </div>

                        <div className='row w-100 mt-4 ml-0 row8'>
                            <div className='col-xs-11 xs-number-column w-100'>
                                <div className='title-container'>
                                <p className='main-title'>35%</p>
                                </div>
                                <p className='main-text'>of all infrastructure projects globally are sustainable, up from 10% a decade ago**</p>
                                <div className='source-text mt-1'>
                                    **Source: <a href="https://www.refinitiv.com/en/infrastructure-investing/insights/sustainable-infrastructure" target='_blank' rel="noreferrer">Refinitiv: The Sustainable Infrastructure Investment Report</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Numbers